import React from 'react'
import { Link, navigate } from 'gatsby'
import logo from '../svg/logo.svg'

export default class IndexPage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      navigate('/a-void-magazine')
    }, 2000)
  }
  render() {
    return (
      <Link className="splash" to="/a-void-magazine">
        <img className="splash__image" src={logo} alt="Continue" />
      </Link>
    )
  }
}
